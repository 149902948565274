import React from 'react';
import { graphql } from 'gatsby';
import { _t } from '../utils/translate';

import Layout from '../components/Layout/Layout';
import TwoColumns from '../components/TwoColumns/TwoColumns';
import PageHeader from '../components/PageHeader/PageHeader';
import ImageCarousel from '../components/ImageCarousel/ImageCarousel';
import SEO from '../components/seo';

import useSetLocale from '../hooks/useSetLocale';

const Visit = ({ data, location }) => {
  useSetLocale();
  const {
    headline,
    intro,
    imageOrVideo,
    visitContent,
    imageCarousel,
    seo,
  } = data.sanityVisit;

  const dark = true;

  return (
    <Layout dark={dark} location={location}>
      <SEO
        title={`${_t(headline)} | SeeSalmon`}
        description={seo && seo.description ? seo.description : null}
        image={seo && seo.image ? seo.image.asset.fixed.src : null}
      />
      <PageHeader
        dark={dark}
        title={headline}
        intro={intro}
        asset={imageOrVideo}
        shortcuts={visitContent}
      />
      <div className="page-padding">
        <ImageCarousel {...imageCarousel} />
        <TwoColumns dark={dark} location={location} contents={visitContent} />
      </div>
    </Layout>
  );
};

export default Visit;

export const query = graphql`
  query {
    sanityVisit {
      headline {
        nb
        en
        _type
      }
      intro {
        nb
        en
        _type
      }
      imageCarousel {
        images {
          _type
          asset {
            fluid(maxWidth: 1640, maxHeight: 780) {
              ...GatsbySanityImageFluid
            }
            _id
          }
        }
        carouselTitle {
          nb
          en
          _type
        }
        _type
        _key
      }
      imageOrVideo {
        ... on SanityVideoUpload {
          ...HdVideoQuery
        }
        ... on SanityMediaImage {
          ...HeadImageQuery
        }
      }
      visitContent {
        ... on SanityInfoBlocks {
          ...SanityInfoBlockQuery
        }
        ... on SanitySubpageVisit {
          ...VisitPageQuery
        }
      }
      seo {
        ...SeoQuery
      }
    }
  }
`;
